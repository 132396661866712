<template>
  <div>
    <div class="container pb-180p">
      <v-data-table
        :headers="headers"
        :items="getDatasets"
        v-model="selectedDataset"
        :page="getPage"
        hide-default-footer
        item-key="name"
        class="elevation-1"
        multi-sort
        show-select
        single-select
        @input="handleSelectCheckbox"
      >

        <template v-slot:top>
          <v-row
            no-gutters
            style="cursor: default;"
          >
            <v-col class="d-flex justify-end mr-3">
              <template>
                <v-btn
                  class="mt-3 mb-3 ml-3"
                  color="error"
                  outlined
                  :disabled="selectedDataset.length === 0"
                  @click="deleteDataset"
                >
                  Remove Dataset
                </v-btn>
              </template>
            </v-col>
          </v-row>
        </template>

        <template v-slot:item.actions="{ item }">
          <div class="text-left">
            <v-tooltip left>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  icon
                  @click="viewAssociatedTransactions(item)"
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon color="primary">
                    mdi-arrow-right-thin-circle-outline
                  </v-icon>
                </v-btn>
              </template>
              <span>View associated transactions</span>
            </v-tooltip>
          </div>
        </template>
      </v-data-table>
    </div>
    <div class="footer">
      <v-pagination
        :value="getPage"
        :length="getNbPages"
        @input="updatePage"
        total-visible="12"
      ></v-pagination>
      <div class="input">
        <v-text-field
          :value="getTransactionsPerPage"
          label="Items per page"
          type="number"
          min="1"
          max="100"
          @change="updateNbTransactionsPerPage"
        ></v-text-field>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'

export default {
  components: {},
  name: 'Datasets',
  computed: {
    ...mapGetters('labels', ['getLabelSchemas']),
    ...mapGetters('datasets', [
      'getDatasets',
      'getSelectedDataset',
      'getTransactions',
      'getPage',
      'getTransactionsPerPage',
      'getNbPages',
      'getFilterLabeled',
      'getFilterSchemas',
    ]),
    ...mapGetters('labelers', [
      'getLabelers',
      'getLabelersPage',
      'getLabelersNbPages',
    ]),
    ...mapGetters('tasks', ['getAllTasks']),
    getLabelersWithNull() {
      return this.getLabelers.concat([{ email_address: 'Anyone', id: 'any' }])
    },
    filterLabeledCheckbox: {
      get() {
        return this.$store.state.datasets.filter_labeled
      },
      async set(value) {
        this.setFilterLabeled(value)
        await this.retrieveCurrentDataset()
      },
    },
    filterDatasetCheckbox: {
      get() {
        return this.$store.state.datasets.filter_dataset
      },
      async set(value) {
        this.setFilterDataset(value)
        this.setFilterLabeled(true)
        await this.retrieveCurrentDataset()
      },
    },
  },
  data() {
    return {
      headers: [
        {
          text: 'Name',
          align: 'start',
          value: 'name',
          sortable: false,
        },
        { text: 'Size', value: 'size', sortable: true },
        { text: 'Updated At', value: 'updated_at', sortable: true },
        { text: 'Created At', value: 'created_at', sortable: true },
        {
          text: 'View Transactions',
          value: 'actions',
          active: true,
          sortable: false,
          class: 'px-0',
        },
      ],
      selected_transactions: [],
      selected_labeler: null,
      selected_schema: null,
      selectedDatasetToAssign: null,
      isAssignmentDialogOpen: false,
      selectedDataset: [],
    }
  },
  methods: {
    ...mapActions('datasets', [
      'retrieveDatasets',
      'retrieveDatasetTransactionIds',
      'retrieveDatasetTransactions',
      'retrieveCurrentDataset',
      'addTransactionsToDataset',
      'removeDataset',
    ]),
    ...mapActions('task', ['createTasks', 'removeLabel']),
    ...mapMutations('datasets', [
      'setSelectedDataset',
      'setSelectedDatasetToAdd',
      'setTransactionsPerPage',
      'setPage',
      'setFilterLabeled',
      'setFilterDataset',
      'setFilterSchemas',
    ]),
    ...mapMutations('labelers', [
      'setLabeler',
      'setLabelersPerPage',
      'setLabelersPage',
      'setActiveFilter',
      'setOrderByNameFilter',
      'setIncludeAdminsFilter',
    ]),
    ...mapActions('tasks', ['handleGetAllTasks']),
    async updateSelectedDataset(e) {
      this.setPage(1)
      this.setSelectedDataset(e)
      await this.retrieveCurrentDataset()
    },
    async updateFilterSchemas(e) {
      this.setFilterSchemas(e)
      await this.retrieveCurrentDataset()
    },
    async setDataset(e) {
      this.selectedDatasetToAssign = e
      this.setSelectedDatasetToAdd(e)
    },
    async addToDataset() {
      await this.addTransactionsToDataset(this.selected_transactions)
      await this.retrieveDatasets()
      await this.retrieveDatasetTransactionIds()
      await this.retrieveCurrentDataset()
    },
    getDatasetFieldText(item) {
      return `${item.name} - (${item.size} txs) - ${item.updated_at}`
    },
    async updateNbTransactionsPerPage(e) {
      this.setPage(1)
      e = parseInt(e)
      if (e != '') {
        if (e < 1) {
          e = 1
        }
        if (e > 100) {
          e = 100
        }
        this.setTransactionsPerPage(e)
        await this.retrieveCurrentDataset()
      }
    },
    async updatePageLabelers(e) {
      this.setActiveFilter(true)
      this.setOrderByNameFilter(true)
      this.setIncludeAdminsFilter(true)
      this.setLabelersPerPage(50)
      this.setLabelersPage(e)
      await this.retrieveCurrentDataset()
    },
    async updatePage(e) {
      this.setPage(e)
      await this.retrieveCurrentDataset()
    },
    async create_task() {
      this.isAssignmentDialogOpen = false
      this.createTasks({
        transaction_ids: this.selected_transactions.map((x) => x.id),
        schema_version: this.selected_schema,
        assignee_id:
          this.selected_labeler.id !== 'any' ? this.selected_labeler : null,
      })
      this.selected_transactions = []
    },
    async removeSelectedLabel(e) {
      if (confirm('Are you sure you want to remove this label?')) {
        this.removeLabel(e.id)
      }
    },
    async selectAllTasks() {
      await this.handleGetAllTasks()
      this.selected_transactions = this.getAllTasks
    },
    async deselectAllTasks() {
      this.selected_transactions = []
    },
    handleSelectCheckbox(e) {
      if (e) {
        this.selectedDataset = e
      } else {
        this.selectedDataset = []
      }
    },
    async deleteDataset() {
      if (confirm('Are you sure you want to delete this dataset?')) {
        if (
          confirm(
            'Are you really sure? This will PERMANENTLY delete the dataset.'
          )
        ) {
          await this.removeDataset(this.selectedDataset[0].name)
        }
      }
    },
    async viewAssociatedTransactions(dataset) {
      await this.retrieveDatasetTransactions()
      this.$router.push({ name: 'Transactions', params: { dataset: dataset } })
    },
  },
  async created() {
    await this.retrieveDatasets()
    await this.retrieveCurrentDataset()
  },
}
</script>

<style></style>